import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Paper from '@material-ui/core/Paper';
import User from '../card/User';
import { Typography } from '@material-ui/core';
import DetailViewModal from '../DetailViewMdal/DetailViewModal';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export default function SpacingGrid(props) {
  const [spacing, setSpacing] = React.useState(2);
  const classes = useStyles();
  let i=0;
  let i1=0;
  let i2=0;
  let i3=0;

 
  
  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  return (
    <Grid container className={classes.root} spacing={2}>
        {/* Not viewed list */}
      <Grid item xs={12}>
      <Typography style={{display:'flex',alignItems:'flex-start',margin:20}} color="primary" variant="h5">{props.user.zeroL>0&&"Not Viewed"}</Typography>
        <Grid container justify="flex-start" spacing={spacing}>
       
        {/* value.status=="0"?(
                  view!="Not Viewed"&&setView("Not Viewed"), */}
          {props.user.zero&&props.user.zero.map((value) => (
            <Grid key={value} item>
              <User profile={value.propic} name={value.name} status={value.status} email={value.email}/>
            </Grid> 
          ))}
        </Grid>
        </Grid>
{/* Selected list */}
        <Grid item xs={12}>
        <Typography style={{display:'flex',alignItems:'flex-start',margin:20}} color="primary" variant="h5">{props.user.oneL>0&&"Selected"}</Typography>
        <Grid container justify="flex-start" spacing={spacing}>
       
        {/* value.status=="1"?(
                select!="Selected"&&setSelect("Selected"),
               */}
          {props.user.one&&props.user.one.map((value) => (

              
              <Grid key={value} item>
                 
                <User profile={value.propic} name={value.name} status={value.status} email={value.email}/>
              </Grid>
          ))}
        </Grid>
        </Grid>
{/* waiting list */}
        <Grid item xs={12}>
        <Typography style={{display:'flex',alignItems:'flex-start',margin:20}} color="primary" variant="h5">{props.user.twoL>0&&"Waiting List"}</Typography>
        <Grid container justify="flex-start" spacing={spacing}>
       
        {/* value.status=="1"?(
                waiting!="Waiting List"&&setView("Waiting List"),
              
               */}
          {props.user.two&&props.user.two.map((value) => (
              
              <Grid key={value} item>
                 
                <User profile={value.propic} name={value.name} status={value.status} email={value.email}/>
              </Grid>
          ))}
        </Grid>
        </Grid>
{/* Rejected List */}
        <Grid item xs={12}>
        <Typography style={{display:'flex',alignItems:'flex-start',margin:20}} color="primary" variant="h5">{props.user.threeL>0&&"Rejected"}</Typography>
        <Grid container justify="flex-start" spacing={spacing}>
        {/* value.status=="1"?(
                waiting!="Rejected"&&setView("Rejected"), */}
          {props.user.three&&props.user.three.map((value) => (
              
              <Grid key={value} item>
                 
                <User profile={value.propic} name={value.name} status={value.status} email={value.email}/>
              </Grid> 
          ))}
        </Grid>
        </Grid>
     
     
    </Grid>
  );
}
