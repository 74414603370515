import firebase from "firebase";
const firebaseApp = firebase.initializeApp({
  //Firebase config
  apiKey: "AIzaSyACuPy4_SP1Rc9ktm7OA6Tiwj_9xenbOeE",
  authDomain: "auth-f01d1.firebaseapp.com",
  projectId: "auth-f01d1",
  storageBucket: "auth-f01d1.appspot.com",
  messagingSenderId: "946882351271",
  appId: "1:946882351271:web:ba1f0cb3e3357a08d80b82",
  measurementId: "G-1VEPEBYEWP"
});

const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };

