import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DetailViewModal from '../DetailViewMdal/DetailViewModal'
import UserModal from '../DetailViewMdal/UserModal'

const useStyles = makeStyles({
  root: {
    maxWidth: 300,

  },
  media: {
    height: 200,
    width:200,
    objectFit:'contain'
  },
});


export default function User({profile,name,email,status}) {
  const classes = useStyles();
const [open,setOpen] = React.useState(false)
const [open1,setOpen1] = React.useState(false)
  return (
    <>
    <DetailViewModal email={email} open={open} close={()=>setOpen(false)} status={status} name={name}/>
    <UserModal email={email} open={open1} close={()=>setOpen1(false)} status={status} name={name}/>
    <Card className={classes.root} >
      <CardActionArea onClick={()=>setOpen(true)}>
        <CardMedia
          className={classes.media}
          image={profile}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
           {name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           {email}
          </Typography>
          <Typography variant="body2" color="primary" component="p" >
           {status==0?"Not Viewed":""}
           {status==1?"Selected":""}
           {status==2?"Waiting List":""}
           {status==3?"Rejected":""}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
          
     
        <Button size="small" color="primary" onClick={()=>setOpen1(true)}>
         View user 
        </Button>
      </CardActions>
    </Card>
    </>
  );
}
