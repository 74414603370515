import React,{useEffect, useState} from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { auth, db, storage } from '../../conf/firebase'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#354e76',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const useStyles = makeStyles({
  table: {
    width: '95%',
    marginTop:100
  },
});

export default function CustomizedTables() {
   const classes = useStyles();
  const [users,setUsers]=useState([]);
  let userArray=[];
  React.useEffect(()=>{

    // function Init(){
     
        db.collection('user/').onSnapshot((doc) => {
          userArray=[];
           doc.forEach((date)=>{
              userArray.push(date.data());
           })
          
           setUsers(userArray);
        })
       
    // }
    // Init();
},[])

  return (
    <>
   
    <TableContainer >
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>
            <StyledTableCell align="center">DOB</StyledTableCell>
            <StyledTableCell align="center">Address</StyledTableCell>
            <StyledTableCell align="center">Mobile</StyledTableCell>
            <StyledTableCell align="center">Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log("users=>",users)}
          {users?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.email}</StyledTableCell>
              <StyledTableCell align="center">{new Date(row.dob.toDate()).getDate()+"-"+new Date(row.dob.toDate()).getMonth()+1+"-"+new Date(row.dob.toDate()).getFullYear()}</StyledTableCell>
              <StyledTableCell align="center">{row.addr}</StyledTableCell>
              <StyledTableCell align="center">{row.no}</StyledTableCell>
              <StyledTableCell align="center">{row.status==1?"Selected":''}{row.status==2?"Waiting List":''}{row.status==3?"Rejected":''}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
