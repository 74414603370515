
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Slider from '@material-ui/core/Slider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    maxWidth:'100%',
    margin:'1%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
 
  sliderStyle:{
        
    width:'70%'
}
});
function valuetext(value) {
        return `${value}°C`;
    }
    

export default function AgeFilter({onChangeEvent}) {
  const classes = useStyles();
  const [value, setValue] = React.useState([1, 100]);

  const handleChange = (event,newValue) => {
   setValue(newValue);
   onChangeEvent(newValue)
  };

  return (
    <div className={classes.root}>
        <Typography id="range-slider" gutterBottom color="primary" variant="h7">
                Age range
            </Typography>
            <Slider
                className={classes.sliderStyle}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
            />
            </div>
  );
}
