import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {auth} from '../../conf/firebase'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import AgeFilter from '../filter/AgeFilter';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

function SideDrawer(props,{list, count,onChangeEvent}) {
    const classes = useStyles();

    function  data(d) {
      props.onChangeEvent(d)
    }
    function review(e){
      console.log("Review "+e);
      props.onChangeState(e)
    }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        {/* <Divider />
        <List>
          {['T', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider /> */}
        <List>
        <ListItem button onClick={()=>review(0)}>
                <ListItemIcon >
                <RecentActorsIcon className={classes.avatar} style={{color:"#354e76"}}/>
                </ListItemIcon>
                <ListItemText primary={"Users"}  />
            </ListItem>
            <ListItem button onClick={()=>review(5)}>
                <ListItemIcon>
                <SupervisedUserCircleIcon className={classes.avatar} style={{color:"blue"}}/>
                </ListItemIcon>
                <ListItemText primary={"Total Applicants - "+props.count}  />
            </ListItem>
            {/* <ListItem button >
                <ListItemIcon>
                <CancelIcon className={classes.avatar} style={{color:"violet"}}/>
                </ListItemIcon>
                <ListItemText primary={"Not Applied - "+props.count1} onClick={()=>review(5)}/>
            </ListItem> */}
            <ListItem button onClick={()=>review(1)}>
                <ListItemIcon>
                <CheckCircleIcon className={classes.avatar} style={{color:"green"}}/>
                </ListItemIcon>
                <ListItemText primary={"Total Selected - "+props.count2} />
            </ListItem>
            <ListItem button onClick={()=>review(2)}>
                <ListItemIcon>
                <RateReviewIcon className={classes.avatar} style={{color:"purple"}}/>
                </ListItemIcon>
                <ListItemText primary={"Total Reviewed - "+props.count3} />
            </ListItem>
            <ListItem button onClick={()=>review(3)}>
                <ListItemIcon>
                <ThumbDownIcon className={classes.avatar} style={{color:"red"}}/>
                </ListItemIcon>
                <ListItemText primary={"Total Rejected - "+props.count4} />
            </ListItem>
            <AgeFilter  onChangeEvent={data}/>
            <ListItem button onClick={()=>{
              auth.signOut()
              window.location.replace('/')
            }}>
              <ListItemIcon>
              <ExitToAppIcon className={classes.avatar} />
                  </ListItemIcon>
              <ListItemText primary="Log out" />
            </ListItem>
        </List>
      </Drawer>
    </div>
  );
}

export default SideDrawer