import React from 'react';
import { auth, db, storage } from '../../conf/firebase'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import './Style.css'
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand() ;
   
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,

    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        alignSelf:'center'
    },
    modal:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formControl: {
                    margin: theme.spacing(1),
                    minWidth: 120,
                },
                selectEmpty: {
                    marginTop: theme.spacing(2),
                    zIndex: 10000
                },
}));

export default function SimpleModal({ name, email, open, close ,status}) {
    const classes = useStyles();
    const [userData, setUserData] = React.useState({})
    const videoRef = React.useRef();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [age, setAge] = React.useState(status);
    // const [open, setOpen] = React.useState(false);

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleChange = (event) => {
        setAge(event.target.value);
        

        db.collection("user/")
            .doc(email)
            .set({
              email: userData.email,
              name: userData.name,
              no: userData.no,
              dob: userData.dob,
              addr: userData.addr,
              gard: userData.gard,
              id: userData.id,
              propic: userData.propic,
              vid: userData.vid,
              status: event.target.value,
            })
            .then(() => {
              //setProgress(100);
              window.location.reload();
             // handleClose();
            }).catch((e)=>{
                console.log(e)
            })
    };
   
    React.useEffect(() => {
      
                function Init() {
                    db.collection('user/').doc(email).onSnapshot((doc) => {
                      
                        setUserData(doc.data())
                    
                       
                    })
        
                }
        
                Init()
            }, [])

    return (
        <div style={{alignItems:'center'}}>

            <Modal
            className={classes.modal}
                open={open}
                onClose={() => close()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className="card_sec">
                    <video
                        id="my-video"
                        className="video-js"
                        controls
                        type="video/mp4"
                    src={userData.vid}
                    >

                    </video>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <Typography variant="h6" color="primary" style={{ margin: '1vW' }}>
                            {name}
                        
                        </Typography>
                        <FormControl className={classes.formControl} style={{ margin: '1vW' }}>

                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                           {/* {setAge(userData.status)} */}
                            <Select
                                style={{ zIndex: 10000, position: 'absolute' ,width:'100%'}}
                                menuPortalTarget={document.body}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                onChange={handleChange}
                            >
                                
                              
                                <MenuItem style={{ zIndex: 10000 }} value={1}>Selected</MenuItem>
                                <MenuItem value={2}>Waiting List</MenuItem>
                                <MenuItem value={3}>Rejected</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

