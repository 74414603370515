import React,{useEffect, useState} from "react";
import {db} from '../conf/firebase';
import Report from '../components/card/Report'
import UserCardView from '../components/Data/UserCardView';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import RateReviewIcon from '@material-ui/icons/RateReview';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import SideDrawer from '../components/SideDrawer/SideDrawer'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import UserView from '../components/Data/UserView';
import User from "../components/card/User";
import ReactExport from "react-export-excel";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


function DownloadExcel({dataval,dataSet={}}){
// console.log(dataSet)
  
  let tempDataValue=[]

  // console.log(dataval)
  if(dataval==0&&dataSet.all){
    dataSet.all.map(data=>{
      var new_startDate= new Date(data.dob.toDate());
      var date= new_startDate.getDate()+ '-' + new_startDate.getMonth()+1 + '-' + new_startDate.getFullYear() 
      tempDataValue.push({
        name:data.name,
        email:data.email,
        address:data.addr,
        no:data.no,
        gname:data.gard,
        dob:date
      })
    })
  }
  else if(dataval==5&&dataSet.all){
    dataSet.all.map(data=>{
      var new_startDate= new Date(data.dob.toDate());
      var date= new_startDate.getDate()+ '-' + new_startDate.getMonth()+1 + '-' + new_startDate.getFullYear() 
      tempDataValue.push({
        name:data.name,
        email:data.email,
        address:data.addr,
        no:data.no,
        gname:data.gard,
        dob:date
      })
    })
  }else if(dataval==1&&dataSet.one){
    // console.log(dataSet.one)
    dataSet.one.map(data=>{
      var new_startDate= new Date(data.dob.toDate());
      var date= new_startDate.getDate()+ '-' + new_startDate.getMonth()+1 + '-' + new_startDate.getFullYear() 
      tempDataValue.push({
        name:data.name,
        email:data.email,
        address:data.addr,
        no:data.no,
        gname:data.gard,
        dob:date
      })
    })
  }else if(dataval==2&&dataSet.two){
    dataSet.two.map(data=>{
      var new_startDate= new Date(data.dob.toDate());
      var date= new_startDate.getDate()+ '-' + new_startDate.getMonth()+1 + '-' + new_startDate.getFullYear() 
      tempDataValue.push({
        name:data.name,
        email:data.email,
        address:data.addr,
        no:data.no,
        gname:data.gard,
        dob:date
      })
    })
  }else if(dataval==3&&dataSet.three){
    dataSet.three.map(data=>{
      var new_startDate= new Date(data.dob.toDate());
      var date= new_startDate.getDate()+ '-' + new_startDate.getMonth()+1 + '-' + new_startDate.getFullYear() 
      tempDataValue.push({
        name:data.name,
        email:data.email,
        address:data.addr,
        no:data.no,
        gname:data.gard,
        dob:date
      })
    })
  }
  // setDataValues(tempDataValue)
  return <ExcelFile element={<button style={{backgroundColor:'#fafafa',padding:'6px',border:'1px solid #c4c4c4',width:'auto',color:'#000000',fontSize:14,position:'absolute', outline: 'none',cursor:'pointer',opacity:.6,borderRadius:3}}>Download as Excel</button>}>
          <ExcelSheet data={tempDataValue} name="Employees">
              <ExcelColumn label="Name" value="name"/>
              <ExcelColumn label="Email" value="email"/>
              <ExcelColumn label="Address" value="address"/>
              <ExcelColumn label="Phone No" value="no"/>
              <ExcelColumn label="Gardean" value="gname"/>
              <ExcelColumn label="Date of birth" value="dob"/>

          </ExcelSheet>
        </ExcelFile>
}
function Home(props) {
  const [userData,setUserData] = useState([])
  const [userData1,setUserData1] = useState([])
 
  const [end,setEnd] = useState(100)
  const [start,setStart] = useState(1)
  const [status,setStatus] = useState(5)
  const [propsData,setPropsData] = useState([])
  function ageCalcution(dateStamp){
    let dob = new Date(dateStamp.toDate())
    let toDay = new Date();
    return (Math.round((toDay-dob)/ (31557600000)))
  }
  let tempArray = []
  let tempArrayOne = []
  let ageFilter = []
  let tempStatusOne = []
  let tempStatusTwo = []
  let tempStatusThree = []
  let tempStatusZero = []
  let tempStatusOne1 = []
  let tempStatusTwo1 = []
  let tempStatusThree1 = []
  let tempStatusZero1 = []
  useEffect(() => {
    function Init(){
     
      
      
      db.collection('user/').onSnapshot((doc)=>{
        tempArray = []
        tempArrayOne = []
       ageFilter = []
       tempStatusOne = []
       tempStatusTwo = []
       tempStatusThree = []
       tempStatusZero = []
       tempStatusOne1 = []
       tempStatusTwo1 = []
       tempStatusThree1 = []
       tempStatusZero1 = []
        //get all user data from firebase
        doc.forEach((date)=>{
          
          if(date.data().vid!='' && ageCalcution(date.data().dob)>=start&& ageCalcution(date.data().dob)<=end && (date.data().status==status||status==5)){
          tempArray.push(date.data())
          }
          if(date.data().vid!=''){
            tempArrayOne.push(date.data())
            }
        })

        //age calculation
        tempArray.forEach(data=>{
          ageCalcution(data.dob)
        })

        //sort by status
      // console.log(tempArray)
        tempArrayOne.forEach(data=>{
          if(data.vid!=''){
          if(data.status==0){
            tempStatusZero.push(data)
          }if(data.status==1){
            tempStatusOne.push(data)
          }if(data.status==2){
            tempStatusTwo.push(data)
          }if(data.status==3){
            tempStatusThree.push(data)
          }}
        })
        tempArray.forEach(data=>{
          if(data.vid!=''){
          if(data.status==0){
            tempStatusZero1.push(data)
          }if(data.status==1){
            tempStatusOne1.push(data)
          }if(data.status==2){
            tempStatusTwo1.push(data)
          }if(data.status==3){
            tempStatusThree1.push(data)
          }}
        })
        // console.log(tempArray)
        // setStatusOne(tempStatusOne)
        // setStatusTwo(tempStatusTwo)
        // setStatusThree(tempStatusThree)
        // setStatusZero(tempStatusZero)
        setUserData({
          zero:tempStatusZero,
          one:tempStatusOne,
          two:tempStatusTwo,
          three:tempStatusThree,
          zeroL:tempStatusZero.length,
          oneL:tempStatusOne.length,
          twoL:tempStatusTwo.length,
          threeL:tempStatusThree.length,
          all:tempArrayOne,
          allL:tempArrayOne.length
        })
        setUserData1({
          zero:tempStatusZero1,
          one:tempStatusOne1,
          two:tempStatusTwo1,
          three:tempStatusThree1,
          zeroL:tempStatusZero1.length,
          oneL:tempStatusOne1.length,
          twoL:tempStatusTwo1.length,
          threeL:tempStatusThree1.length,
          all:tempArray,
          allL:tempArray.length
        })
        // console.log({
        //   zero:tempStatusZero,
        //   one:tempStatusOne,
        //   two:tempStatusTwo,
        //   three:tempStatusThree,
        //   all:tempArray
        // })
        // setUserData(tempArray)
        // let tempList = [{
        //   'title':"Total Applicants",
        //   'icon':SupervisedUserCircleIcon,
        //   'count':userData.length,
        //   'iconColor':"blue"
        // },{
        //   'title':"Not Applied",
        //   'icon':CancelIcon,
        //   'count':statusZero.length,
        //   'iconColor':"red"
        // },{
        //   'title':"Total Selected",
        //   'icon':CheckCircleIcon,
        //   'count':statusThree.length,
        //   'iconColor':"green"
        // },{
        //   'title':"Total Reviewed",
        //   'icon':RateReviewIcon,
        //   'count':statusOne.length,
        //   'iconColor':"purple"
        // },{
        //   'title':"Total Rejected",
        //   'icon':ThumbDownIcon,
        //   'count':statusTwo.length,
        //   'iconColor':"red"
        // }]
        // setPropsData(tempList)
      })
      
    }
    Init()
  }, [start,end,status])

  function DataProps(e){
    setStart(e[0])
    setEnd(e[1])
  }
  function setReview(f)
  {
    // console.log(f);
      setStatus(f);
  }
  return <div style={{flexDirection:'row',display:'flex'}}>
    <SideDrawer list={propsData} count={userData.allL} count1={userData.zeroL}  onChangeEvent={DataProps} count2={userData.oneL} count4={userData.threeL} count3={userData.twoL} onChangeState={setReview} />
  
    <div style={{display:'flex',flexDirection:'column',marginTop:'2vw',width:'100%',justifyContent:'space-around'}}>
        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:'2vw'}}>

          <Report icon={SupervisedUserCircleIcon} title={"Total Applicants"} count={userData.allL} iconColor={"blue"}/>
          {/* <Report icon={CancelIcon} title={"Not Applied"} count={userData.zeroL} iconColor={"violet"}/> */}
          <Report icon={CheckCircleIcon} title={"Total Selected"} count={userData.oneL} iconColor={"green"}/>
          <Report icon={RateReviewIcon} title={"Total Reviewed"} count={userData.twoL} iconColor={"purple"}/>
          <Report icon={ThumbDownIcon} title={"Total Rejected"} count={userData.threeL} iconColor={"red"}/>
        </div>
        
        <div style={{display:'flex',flexDirection:'row',width:'100%',marginTop:'2vw',margin:'1vw',justifyContent:'space-around'}}>
        <DownloadExcel dataval={status} dataSet={userData}/>
          {console.log(userData1)}
          {status==0?<UserView />:
          <UserCardView user={userData1} />
          }
          
        </div>
    </div>
  </div>;
}

export default Home;
