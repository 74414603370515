import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { auth, db } from "../conf/firebase";
import Alert from "@material-ui/lab/Alert";
import "./style/Style.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { UserProvider, UserContext } from "../conf/UserContext";

const useStyles = makeStyles((theme) => ({
  btn: {
    backgroundColor: "#eca742",
    color: "#fff",
    marginTop: 10,
    marginBottom: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    margin: 5,
  },
  alert: {
    marginBottom: 10,
  },
}));

function Login(props) {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [state, setState] = useState(false);
  const [error, setError] = useState(false);

  function Signin(e) {
    e.preventDefault();
    setState(true);
    setError(false);
    auth
      .signInWithEmailAndPassword(email, password)
      .then((data) => {
        if(data){
          let email = data.email
            db.collection("admin/")
            .doc(email)
            .onSnapshot((doc) => {
              if(doc.data()){
                window.location.pathname = "/Home";
              }else{
                console.log(e);
                setState(false);
                setError(true);
              }
            });
        }
        
      })
      .catch((e) => {
        console.log(e);
        setState(false);
        setError(true);
      });
  }

  return (
    <div className="login">
      <form className={classes.form} onSubmit={Signin}>
        {error ? (
          <Alert severity="error" className={classes.alert}>
            Invalid credentials
          </Alert>
        ) : (
          ""
        )}
        <TextField
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          required={true}
          type="email"
          value={email}
          label="Email Id"
          className={classes.input}
          variant="outlined"
        />

        <TextField
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          required={true}
          type="password"
          value={password}
          variant="outlined"
          label="Password"
          className={classes.input}
        />



        <Button
          type="submit"
          disabled={state ? true : false}
          id="btn"
          className={classes.btn}
        >
          Login
        </Button>
        {state ? <LinearProgress /> : ""}
      </form>
    </div>
  );
}

function Signup(props) {
  const [state, setState] = useState(true);
  const userAuth = useContext(UserContext);
  const [user, setUser] = useState();
  const [reset, setReset] = useState(false);

  useEffect(() => {
    const authV = () => {
      if (userAuth) {
        console.log(userAuth);
        props.history.push("/Home");
        return;
      } else {
        props.history.push("/");
      }
    };
    authV();
  }, [userAuth]);
  return (
    <div className="sign">
      <div className="container">
        <Login props={props} rePassword={() => setReset(true)} />
      </div>
      <span
        className="footer_copy
      "
      >
        © 2021 Cairns Has Talents. Designed & Developed by{" "}
        <a href="https://www.woxro.com/">Woxro</a>
      </span>
    </div>
  );
}

export default withRouter(Signup);
