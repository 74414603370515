import React from 'react';
import { auth, db, storage } from '../../conf/firebase'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import './Style.css'
function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand() ;
   
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,

    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        //alignSelf:'center'
        justifyContent:'center'
    },
    modal:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formControl: {
                    margin: theme.spacing(1),
                    minWidth: 120,
                },
                selectEmpty: {
                    marginTop: theme.spacing(2),
                    zIndex: 10000
                },
                media: {
                    height: 300,
                    width: 300,
                    resize:'contain'
                  },
}));

export default function SimpleModal({ name, email, open, close ,status}) {
    const classes = useStyles();
    const [userData, setUserData] = React.useState({})
    const [dob, setDob] = React.useState()
    const videoRef = React.useRef();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [age, setAge] = React.useState(status);
    // const [open, setOpen] = React.useState(false);

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleChange = (event) => {
        setAge(event.target.value);
        

        db.collection("user/")
            .doc(email)
            .set({
              email: userData.email,
              name: userData.name,
              no: userData.no,
              dob: userData.dob,
              addr: userData.addr,
              gard: userData.gard,
              id: userData.id,
              propic: userData.propic,
              vid: userData.vid,
              status: event.target.value,
            })
            .then(() => {
              //setProgress(100);
              window.location.reload();
             // handleClose();
            }).catch((e)=>{
                console.log(e)
            })
    };
   
    React.useEffect(() => {
      
                function Init() {
                    db.collection('user/').doc(email).onSnapshot((doc) => {
                        
                        
                            // if(doc.email==email)
                            // {
                                setUserData(doc.data())
                                setDob(new Date(doc.data().dob.toDate()).getDate()+"-"+new Date(doc.data().dob.toDate()).getMonth()+1+"-"+new Date(doc.data().dob.toDate()).getFullYear())
                               // console.log(email)
                            // } 
                        
                    })
        
                }
        
                Init()
            }, [])

    return (
        <div style={{alignItems:'center',width:'100%',backgroundColor:'#354e76'}}>

            <Modal
                className={classes.modal}
                open={open}
                onClose={() => close()}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={userData.propic}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {userData.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           EMAIL : {userData.email}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           DOB : {dob}
           
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
           Address : {userData.addr}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Mobile : {userData.no}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
            </Modal>
        </div>
    );
}

